/* BANNER */

#banner{
    color: black;
    background-color: rgb(255, 255, 255);
    padding: 5% 5% 5% 5%;
    margin-top: 5%;
}

#banner img{
    width: 100%;
}


/* PLANOS */

#planos{
    margin-top: 5%;
    color: rgb(100, 100, 100);
    background-color: rgb(255, 255, 255);
    padding: 5% 15% 5% 15%;
}

#planos img{
    width: 100%;
}

   
