#comp{

    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;

}

#justificar{

    text-align: justify;
    margin-top: 5%;
    margin-bottom: 5%;

}

/* PLANOS */

#planos{
    color: black;
    background-color: rgb(255, 255, 255);
    padding: 5% 15% 5% 15%;
}

#planos img{
    width: 100%;
}
