#comp{

    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;

}
#justificar{

    text-align: justify;
    margin-top: 5%;
    margin-bottom: 5%;

}
