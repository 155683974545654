/* BANNER */

#banner{
    color: black;
    background-color: rgb(255, 255, 255);
    padding: 5% 5% 5% 5%;
    margin-top: 5%;
}

#banner img{
    width: 100%;
}


/* PLANOS */

#planos{
    color: rgb(100, 100, 100);
    background-color: rgb(255, 255, 255);
    padding: 5% 15% 5% 15%;
}

#planos img{
    width: 100%;
}


   
.card-1:hover {
    background: none;
    color: #222222;
    text-decoration: none;
    border-style:none;
    border-top-color: #DFDFDF;
    border-right-color: #666;
    border-bottom-color: #333;
    border-left-color: #858585;
}
   
