#footer{
    text-align: center;
    background-color: rgb(135, 135, 135);
    padding: 3% 5%;
}

.icon{
    color: rgb(90, 90, 90);
    margin-bottom: 15px;
}

