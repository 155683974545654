.btnCancelar{
    border-color:rgb(29, 33, 104);
    margin: 20px 20px 0px 20px;
    min-width: 100px;

}

.btnSalvar{
    background-color: rgb(29, 33, 104);
    margin: 20px 20px 0px 20px;
    min-width: 100px;

}