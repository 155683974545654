#complace{
    text-align: center;
    background-color: rgb(255, 154, 0);
    padding: 3% 5%;
}

#col-1{
    text-align: left;
}
#col-2{
    text-align: left;
}
#col-3{
    text-align: left;
}

a:link {
    background: none;
    color: #222222;
    text-decoration: none;
    border-style:none;
    border-top-color: #DFDFDF;
    border-right-color: #666;
    border-bottom-color: #333;
    border-left-color: #858585;
}
   
 a:hover {
    color: #222222;
    font-weight: bold;
    cursor: pointer;
}