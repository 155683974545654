.titulo{
    margin-top: 10px;
}
.btnOs{
    margin-right: 15px;
}
.btnPesquisar{
    background-color: rgb(29, 33, 104);
}
.btn-cli{
    margin-right: 15px;
}